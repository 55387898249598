$bgDarkLight: #011b3f;
$bgDarkDark: #010f25;

.landing{
    //background: linear-gradient(145deg, $bgDarkLight, $bgDarkDark);
    background: var(--bg-color);
    background-position: center, center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    .lHeader{
        position: absolute;
        top: 0;
        z-index: 999;
        width: 100%;
    }
    .landing-section{
        height: 60vh;
        @media(max-width: 480px){
            margin-top: 0 !important;
        }
        .landing-wrapper{
            height: 100%;
            margin: 0 10%;
            justify-content: space-between;
            position: relative;
            @media(max-width: 480px){
                margin: 0;
            }
            .landing-texts{
                width: 40%;
                margin: 0 !important;
                position: absolute;
                top: 15%;
                left: 5%;
                @media(min-width: 1440px){
                    top: 15%;
                }
                @media(max-width: 480px){
                    width: 90%;
                    margin-top: 20px;
                }
                .landing-txt-header{
                    font-weight: 900;
                    line-height: 1.2;
                    color: var(--text-color);
                    margin-bottom: 30px;
                    @media(max-width: 480px){
                        font-size: 2.7rem;
                    }
                }
                .landing-txt-paragraph{
                    font-weight: 400;
                    color: var(--text-color);
                    margin-bottom: 50px;
                }
                .lng-cta{
                    background-color: #256aff;
                    color: #ffffff;
                    padding: 10px 25px;
                    border-radius: 23px;
                    cursor: pointer;
                }
            }
            .landing-image{
                margin: 0 !important;
                position: absolute;
                top: 13%;
                right: -5%;
                width: 50%;
                padding-right: 10%;
                @media(max-width: 480px){
                    display: none !important;
                }
                .codeplayArea{
                    background-color: var(--gradient--pale-ocean);
                    padding: 10px;
                    transform: skewX(-10deg);
                    margin-left: 10%;
                }
                .img-landing{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .landingBgOne, .landingBgTwo, .landingBgThree{
            position: absolute;
        }
        .landingBgOne{
            top: 0;
            height: 60vh;
            background-color: transparent !important;
            width: 65%;
            z-index: 0 !important;
            margin: 0 0 0 -10%;
            -webkit-transform: skew(-20deg);
            -moz-transform: skew(-20deg);
            -o-transform: skew(-20deg);
            @media(max-width: 680px){
                background-color: transparent !important;
            }
            .bGOneUnskew{
                margin: 0 0 0 20%;
                -webkit-transform: skew(20deg);
                -moz-transform: skew(20deg);
                -o-transform: skew(20deg);
                padding-top: 5%;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: flex-end;
                position: relative;
                .bgHex{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                } 
            }
        }
        .landingBgTwo{
            top: 50%;
            right: 20%;
            .BgTwoHex{
                
            }
        }
    }
}