.specialization {
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  padding: 5% 0;
  background: var(--bg-color);
  margin-top: -4%;
}
.specialization .specWrapper {
  margin: 0 10%;
}
@media (max-width: 480px) {
  .specialization .specWrapper {
    margin: 0 3%;
  }
}
.specialization .specWrapper .specializationHeader {
  text-align: center;
  height: inherit;
  background: inherit;
  -webkit-background-clip: text;
  background-clip: text;
  color: var(--text-color);
  font-family: Apercu, source sans pro, system, system-ui, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 900;
}
@media (max-width: 480px) {
  .specialization .specWrapper .specializationHeader {
    font-family: Apercu, source sans pro, system, system-ui, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif !important;
  }
}
.specialization .specWrapper .spectxts {
  font-size: 0.9rem;
  color: var(--text-color);
}
.specialization .specWrapper .specCards {
  margin-top: 5%;
  width: 85%;
  margin-left: 10%;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  justify-content: center;
  justify-items: center;
}
@media (min-width: 1440px) {
  .specialization .specWrapper .specCards {
    width: 90%;
    margin-left: 5%;
  }
}
@media (max-width: 480px) {
  .specialization .specWrapper .specCards {
    width: 100%;
    margin-left: 0 !important;
  }
}
.specialization .specWrapper .specCards .specCrds {
  grid-column: span 1;
  height: auto;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(rgba(6, 0, 1, 0.5), rgba(6, 0, 77, 0.6));
  border-radius: 10px;
  padding: 20px;
  position: relative;
  background-position: center, center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid rgb(184, 234, 163);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 18px 0px, rgba(0, 0, 0, 0.1) 0px 3px 5px -1px;
  transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
}
.specialization .specWrapper .specCards .specCrds:hover {
  background: rgb(100, 105, 98) !important;
}
@media (max-width: 1000px) {
  .specialization .specWrapper .specCards .specCrds {
    grid-column: span 2;
  }
}
.specialization .specWrapper .specCards .specCrds::before {
  pointer-events: none;
  content: "";
  background: linear-gradient(130deg, rgba(2, 92, 98, 0.7529411765), rgba(2, 92, 98, 0.7529411765) 41.07%, #000000 76.05%);
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: screen;
  opacity: 0.75;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.specialization .specWrapper .specCards .specCrds .crdsTitle {
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 1.2 !important;
  letter-spacing: 1.1px;
  margin-top: 5px;
  margin-bottom: 10px;
  height: inherit;
  background: inherit;
  -webkit-background-clip: text;
  background-clip: text;
  color: #ffffff;
  font: 900 5vmin/3rem cookie, cursive;
}
@media (max-width: 480px) {
  .specialization .specWrapper .specCards .specCrds .crdsTitle {
    font-family: Apercu, source sans pro, system, system-ui, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif !important;
  }
}
.specialization .specWrapper .specCards .specCrds p {
  font-size: 1rem;
  margin-bottom: 50px;
  color: #ffffff;
}
.specialization .specWrapper .specCards .specCrds .specKnowMore {
  position: absolute;
  bottom: 15px;
  left: 20px;
  font-size: 0.8rem;
  letter-spacing: 1.2;
  padding: 10px 20px;
  background-color: var(--color--vivid-cyan-blue);
  border-radius: 6px;
  color: #ffffff;
}/*# sourceMappingURL=specialization.css.map */