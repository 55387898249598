.brand-logo, .navigation {
  height: 40px;
}

.header {
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: var(--bg-color);
  border-bottom: 1px solid #cbcbcb;
  width: 100%;
}
@media (min-width: 1440px) {
  .header .header-container {
    margin: 0 10%;
  }
}
@media (max-width: 1440px) {
  .header .header-container {
    margin: 0 5%;
  }
}
.header .header-container .header-wrapper .brand-logo {
  width: 40%;
  position: relative;
}
@media (max-width: 480px) {
  .header .header-container .header-wrapper .brand-logo {
    width: 50% !important;
  }
}
.header .header-container .header-wrapper .brand-logo .logoWrapper {
  position: relative;
  top: 0;
  left: 10%;
  z-index: 999;
  width: 150px;
  height: auto;
  margin-top: -5px;
}
@media (max-width: 480px) {
  .header .header-container .header-wrapper .brand-logo .logoWrapper {
    left: 0;
    width: 100px;
  }
}
.header .header-container .header-wrapper .brand-logo .logoWrapper .logo {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.header .header-container .header-wrapper .posUnset {
  position: unset !important;
}
.header .header-container .header-wrapper .navigation {
  align-items: center;
  margin-top: -20px;
  width: 60%;
  position: relative;
  z-index: 999;
}
@media (max-width: 320px) {
  .header .header-container .header-wrapper .navigation {
    gap: 1rem !important;
  }
}
.header .header-container .header-wrapper .navigation .openMobiMenu {
  display: none;
  width: 25px;
  fill: var(--text-color);
}
.header .header-container .header-wrapper .navigation .menuMbi .closeMobiMenu {
  display: none;
}
.header .header-container .header-wrapper .navigation .menuMbi ul {
  align-items: center;
}
.header .header-container .header-wrapper .navigation .menuMbi ul .mobiLogo {
  display: none;
}
.header .header-container .header-wrapper .navigation .menuMbi ul li .nav-links {
  letter-spacing: 1.5px;
  cursor: pointer;
  margin: 0 20px;
  color: var(--text-color);
}
.header .header-container .header-wrapper .navigation .header-cta .header-cta-btn {
  background-color: #256aff;
  border-radius: 23px;
  padding: 8px 25px;
  color: #ffffff;
}
@media (max-width: 500px) {
  .header .header-container .header-wrapper .navigation .header-cta .header-cta-btn {
    padding: 5px 10px !important;
  }
}

@media (max-width: 1050px) {
  .header-container {
    margin: 0 5% !important;
  }
  .brand-logo .logo-text {
    font-size: 2rem;
  }
  .openMobiMenu {
    display: unset !important;
  }
  .shwMobiMenu {
    right: 0 !important;
    left: 0 !important;
    height: 100vh !important;
  }
  .shwMobiMenu ~ .navigation {
    position: unset !important;
  }
  .header-cta .header-cta-btn {
    background-color: #4f60ff;
    border-radius: 6px;
    font-size: 0.9rem;
  }
  .menuMbi {
    position: absolute;
    z-index: 999;
    background-color: var(--bg-color);
    top: 0;
    bottom: 0;
    right: unset;
    left: -200%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transition: 3s, ease-in-out;
  }
  .menuMbi .closeMobiMenu {
    display: unset !important;
    position: absolute;
    top: 20px;
    right: 25px;
    width: 15px;
    fill: var(--text-color);
    cursor: pointer;
  }
  .menuMbi .closeMobiMenu:hover {
    fill: #4f60ff;
  }
  .menuMbi ul {
    align-items: center;
    flex-direction: column !important;
    vertical-align: middle;
    margin-top: 150px;
  }
  .menuMbi ul .mobiLogo {
    display: unset !important;
    position: absolute;
    top: 10px;
    left: 20px;
    font-size: 2rem;
    margin-top: 5px;
    color: var(--text-color);
  }
  .menuMbi ul li {
    margin: 15px 0;
  }
  .menuMbi ul li .nav-links {
    font-size: 1.4rem;
  }
}/*# sourceMappingURL=header.css.map */