html, body {
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  font-family: Apercu, source sans pro, system, system-ui, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;
}

/* Apercu font */
@font-face {
  font-family: Apercu;
  src: url(../../assets/fonts/apercu/Apercu-Light.eot);
  src: url(../../assets/fonts/apercu/Apercu-Light.eot?#iefix) format("embedded-opentype"), url(../../assets/fonts/apercu/Apercu-Light.woff2) format("woff2"), url(../../assets/fonts/apercu/Apercu-Light.woff) format("woff"), url(../../assets/fonts/apercu/Apercu-Light.ttf) format("truetype"), url(../../assets/fonts/apercu/Apercu-Light.svg#Apercu-Light) format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Apercu;
  src: url(../../assets/fonts/apercu/Apercu-LightItalic.eot);
  src: url(../../assets/fonts/apercu/Apercu-LightItalic.eot?#iefix) format("embedded-opentype"), url(../../assets/fonts/apercu/Apercu-LightItalic.woff2) format("woff2"), url(../../assets/fonts/apercu/Apercu-LightItalic.woff) format("woff"), url(../../assets/fonts/apercu/Apercu-LightItalic.ttf) format("truetype"), url(../../assets/fonts/apercu/Apercu-LightItalic.svg#Apercu-LightItalic) format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: Apercu;
  src: url(../../assets/fonts/apercu/Apercu-Regular.eot);
  src: url(../../assets/fonts/apercu/Apercu-Regular.eot?#iefix) format("embedded-opentype"), url(../../assets/fonts/apercu/Apercu-Regular.woff2) format("woff2"), url(../../assets/fonts/apercu/Apercu-Regular.woff) format("woff"), url(../../assets/fonts/apercu/Apercu-Regular.ttf) format("truetype"), url(../../assets/fonts/apercu/Apercu-Regular.svg#Apercu-Regular) format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Apercu;
  src: url(../../assets/fonts/apercu/Apercu-Italic.eot);
  src: url(../../assets/fonts/apercu/Apercu-Italic.eot?#iefix) format("embedded-opentype"), url(../../assets/fonts/apercu/Apercu-Italic.woff2) format("woff2"), url(../../assets/fonts/apercu/Apercu-Italic.woff) format("woff"), url(../../assets/fonts/apercu/Apercu-Italic.ttf) format("truetype"), url(../../assets/fonts/apercu/Apercu-Italic.svg#Apercu-Italic) format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: Apercu;
  src: url(../../assets/fonts/apercu/Apercu-Medium.eot);
  src: url(../../assets/fonts/apercu/Apercu-Medium.eot?#iefix) format("embedded-opentype"), url(../../assets/fonts/apercu/Apercu-Medium.woff2) format("woff2"), url(../../assets/fonts/apercu/Apercu-Medium.woff) format("woff"), url(../../assets/fonts/apercu/Apercu-Medium.ttf) format("truetype"), url(../../assets/fonts/apercu/Apercu-Medium.svg#Apercu-Medium) format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Apercu;
  src: url(../../assets/fonts/apercu/Apercu-MediumItalic.eot);
  src: url(../../assets/fonts/apercu/Apercu-MediumItalic.eot?#iefix) format("embedded-opentype"), url(../../assets/fonts/apercu/Apercu-MediumItalic.woff2) format("woff2"), url(../../assets/fonts/apercu/Apercu-MediumItalic.woff) format("woff"), url(../../assets/fonts/apercu/Apercu-MediumItalic.ttf) format("truetype"), url(../../assets/fonts/apercu/Apercu-MediumItalic.svg#Apercu-MediumItalic) format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: Apercu;
  src: url(../../assets/fonts/apercu/Apercu-Bold.eot);
  src: url(../../assets/fonts/apercu/Apercu-Bold.eot?#iefix) format("embedded-opentype"), url(../../assets/fonts/apercu/Apercu-Bold.woff2) format("woff2"), url(../../assets/fonts/apercu/Apercu-Bold.woff) format("woff"), url(../../assets/fonts/apercu/Apercu-Bold.ttf) format("truetype"), url(../../assets/fonts/apercu/Apercu-Bold.svg#Apercu-Bold) format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Apercu;
  src: url(../../assets/fonts/apercu/Apercu-BoldItalic.eot);
  src: url(../../assets/fonts/apercu/Apercu-BoldItalic.eot?#iefix) format("embedded-opentype"), url(../../assets/fonts/apercu/Apercu-BoldItalic.woff2) format("woff2"), url(../../assets/fonts/apercu/Apercu-BoldItalic.woff) format("woff"), url(../../assets/fonts/apercu/Apercu-BoldItalic.ttf) format("truetype"), url(../../assets/fonts/apercu/Apercu-BoldItalic.svg#Apercu-BoldItalic) format("svg");
  font-weight: 700;
  font-style: italic;
}
/*===theme toggler===*/
[data-theme=light] {
  --bg-color: #f2fffa;
  --bg-color-inverse: #171c28;
  --text-color: #444452;
  --text-color-inverse: #e6e6e6;
  --bg-card: #86d9e1;
  --back-related: #98b2dd;
  --toggle-transform: translate(0);
  transition: 0.5s;
}

[data-theme=dark] {
  --bg-color: #171c28;
  --bg-color-inverse: #d6ffef;
  --text-color: #e6e6e6;
  --text-color-inverse: #444452;
  --bg-card: #2e2e5b;
  --back-related: #1e304d;
  --toggle-transform: translate(120%);
  transition: 0.5s;
}

:root {
  --bg-color: #d6ffef;
  --color--vivid-cyan-blue: #0693e3;
}

* {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

html, body {
  border-style: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  width: 100%;
  color: var(--text-color);
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  position: relative;
  z-index: 0 !important;
  scroll-behavior: smooth;
  line-height: 1.5;
}

.App {
  background-color: var(--bg-color);
}

body::-webkit-scrollbar {
  width: 5px;
  background-color: rgba(255, 255, 255, 0);
}
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 23px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*===hide body scrollbar===*/
.hideBodyScrollBar {
  overflow: hidden !important;
}

.contents {
  transition: all 0.2s ease-in-out 0s;
}

.lightMode, .darkMode {
  stroke: var(--text-color);
  height: 17px;
  width: 17px;
}

.themeControls {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  margin-top: 23px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.themeControls .themeTogglers {
  position: relative;
}
.themeControls .themeTogglers .lightMode, .themeControls .themeTogglers .darkMode {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  transition: 0.15s ease-in-out 0s;
}

.hidecontents {
  display: none !important;
}

.sCounter, .secCounter, .pCounter, .eCounter {
  font-family: Inconsolata, monospace !important;
}

.cookieConsent {
  width: 100%;
  background: var(--bg-color-inverse);
  position: fixed;
  right: 0;
  padding: 14px 64px 20px 20px;
  z-index: 98;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.cookieText {
  font-size: 17px;
  line-height: 20px;
  color: var(--text-color-inverse);
  letter-spacing: 0.1px;
  margin-right: 30px;
}

.cookiehiglight {
  font-style: italic;
  font-weight: 700;
}

.privacyPolicy {
  text-decoration: underline;
}

.button {
  font-size: 14px;
  line-height: 15px;
  padding: 9px 22px 10px;
  border: 1px solid var(--text-color-inverse);
  color: var(--text-color-inverse);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.5px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  background: transparent;
  transition: color 0.15s ease-in-out 0s;
  display: inline-block;
}
.button:hover {
  background-color: var(--color--vivid-cyan-blue);
  color: #fff;
  border: none;
  margin: 1px;
}/*# sourceMappingURL=globals.css.map */