.specialization{
    height: min-content;
    padding: 5% 0;
    background: var(--bg-color);
    margin-top: -4%;
    .specWrapper{
        margin: 0 10%;
        @media(max-width: 480px){
            margin: 0 3%;
        }
        .specializationHeader{
            text-align: center;
            height: inherit;
            background: inherit;
            -webkit-background-clip: text;
                    background-clip: text;
            color: var(--text-color);
            font-family: Apercu,source sans pro,system,system-ui,-apple-system,BlinkMacSystemFont,Roboto,Helvetica,Arial,sans-serif !important;
            font-weight: 900;
            @media(max-width: 480px){
                font-family: Apercu,source sans pro,system,system-ui,-apple-system,BlinkMacSystemFont,Roboto,Helvetica,Arial,sans-serif !important;
            }
        }
        .spectxts{
            font-size: .9rem;
            color: var(--text-color);
        }
        .specCards{
            margin-top: 5%;
            width: 85%;
            margin-left: 10%;
            grid-template-columns: repeat(2, 1fr);
            gap: 1.5rem;
            justify-content: center;
            justify-items: center;
            @media(min-width: 1440px){
                width: 90%;
                margin-left: 5%;
            }
            @media(max-width: 480px){
                width: 100%;
                margin-left: 0 !important;
            }
            .specCrds{
                grid-column:span 1;
                height: auto;
                width: 100%;
                overflow: hidden;
                //background: linear-gradient(45deg, #6E6BFF, #4A48B9);
                background: linear-gradient(rgba(6, 0, 1, 0.5),rgba(6, 0, 77, 0.6));
                border-radius: 10px;
                padding: 20px;
                position: relative;
                background-position: center, center;
                background-repeat: no-repeat;
                background-size: cover;
                border: 1px solid rgb(184, 234, 163);
                box-shadow: rgba(0, 0, 0, 6%) 0px 1px 18px 0px, rgba(0, 0, 0, 10%) 0px 3px 5px -1px;
                transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
                &:hover{
                    background: rgb(100, 105, 98) !important;
                }
                @media(max-width: 1000px){
                    grid-column:span 2;
                }
                &::before{
                    pointer-events: none;
                    content: "";
                    background: linear-gradient(130deg,#025c62c0,#025c62c0 41.07%,#000000 76.05%);
                    position: absolute;
                    top: 0;
                    left: 0;
                    mix-blend-mode: screen;
                    opacity: .75;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                }
                .crdsTitle{
                    font-size: 1.7rem;
                    font-weight: 700;
                    line-height: 1.2 !important;
                    letter-spacing: 1.1px;
                    margin-top: 5px;
                    margin-bottom: 10px;
                    height: inherit;
                    background: inherit;
                    -webkit-background-clip: text;
                            background-clip: text;
                    color: #ffffff;
                    font: 900 5vmin/3rem cookie, cursive;
                    //filter: invert(1) grayscale(1) contrast(9);
                    @media(max-width: 480px){
                        font-family: Apercu,source sans pro,system,system-ui,-apple-system,BlinkMacSystemFont,Roboto,Helvetica,Arial,sans-serif !important;
                    }
                }
                p{
                    font-size: 1rem;
                    margin-bottom: 50px;
                    color: #ffffff;
                }
                .specKnowMore{
                    position: absolute;
                    bottom: 15px;
                    left: 20px;
                    font-size: 0.8rem;
                    letter-spacing: 1.2;
                    padding: 10px 20px;
                    background-color: var(--color--vivid-cyan-blue);
                    border-radius: 6px;
                    color: #ffffff;
                }
            }
        }
    }
}