@forward 'globals/index';

$bgDarkLight: #01122a;
$bgDarkDark: #040d1b;
$colorLight: #f3f3f3;

*{
    padding: 0;
    margin: 0;
}
li{
    list-style: none;
}
html,body{
    border-style: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--bg-color);
    //background-repeat: no-repeat;
    //background-position: 50% 0;
    width: 100%;
    color: var(--text-color);
    overflow-x: hidden;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    position: relative;
    z-index: 0 !important;
    scroll-behavior: smooth;
    line-height: 1.5;
}
.App{
  background-color: var(--bg-color);
}
body{
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #ffffff00;
  }
  &::-webkit-scrollbar-track {
      background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 23px;
  }
}

//hide increment and decrement in number input types
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// //vertical scrollbar
// body::-webkit-scrollbar {
//     width: 5px;
//     background-color: #2f323900;
// }
// @media(max-width: 600px){
//     *{
//         -ms-overflow-style: none;
//     }
//     body::-webkit-scrollbar {
//         scrollbar-color: transparent transparent;
//     }
// }

/*===hide body scrollbar===*/
.hideBodyScrollBar{
  overflow: hidden !important;
}
.contents{
  transition: all 0.2s ease-in-out 0s;
}
.lightMode, .darkMode{
  stroke: var(--text-color);
  height: 17px;
  width: 17px;
}
.themeControls{
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  margin-top: 23px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  .themeTogglers{
    position: relative;
    .lightMode, .darkMode{
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      transition: 0.15s ease-in-out 0s;
    }
  }
}
.hidecontents{
  display: none !important;
}

.sCounter, .secCounter, .pCounter, .eCounter{
  font-family: Inconsolata,monospace !important;
}

//cookie notification
.cookieConsent{
  width: 100%;
  //background: url('../../images/cookie-bg.png') center center / 100% 100% no-repeat;
  background: var(--bg-color-inverse);
  position: fixed;
  right: 0;
  padding: 14px 64px 20px 20px;
  z-index: 98;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.cookieText{
  font-size: 17px;
  line-height: 20px;
  color: var(--text-color-inverse);
  letter-spacing: 0.1px;
  margin-right: 30px;
}
.cookiehiglight{
  font-style: italic;
  font-weight: 700;
}
.privacyPolicy{
  text-decoration: underline;
}

.button{
  font-size: 14px;
  line-height: 15px;
  padding: 9px 22px 10px;
  border: 1px solid var(--text-color-inverse);
  color: var(--text-color-inverse);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.5px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  background: transparent;
  transition: color 0.15s ease-in-out 0s;
  display: inline-block;
  &:hover{
    background-color: var(--color--vivid-cyan-blue);
    color: #fff;
    border: none;
    margin: 1px;
  }
}