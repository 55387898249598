.blogParent{
    transition: .4s;
}
/*==== blog landing ====*/
.showSearchModal{
    display: unset !important;
}
.searchModal{
    display: none;
    height: 100vh;
    width: 100%;
    position: fixed;
    overflow-y: scroll;
    top: 0;
    z-index: 999;
    background-color: var(--bg-color);
    @media(max-width: 600px){
        padding: 0 3%;
    }
    &::-webkit-scrollbar {
        width: 5px;
        background-color: #ffffff00;
        @media(max-width: 600px){
            width: 0px;
            background-color: #ffffff00;
        }
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 23px;
    }
    .closeSearch{
        height: 30px;
        fill: var(--text-color);
        position: absolute;
        top: 20px;
        right: 30px;
        cursor: pointer;
    }
    .smodSearchForm{
        width: 100%;
        margin-top: 8%;
        margin-bottom: 5%;
        @media(max-width: 480px){
            margin-top: 20%;
            margin-bottom: 15%;
        }
        form{
            width: 30%;
            margin: 0 auto;
            @media(max-width: 700px){
                width: 50%;
            }
            @media(max-width: 480px){
                width: 80%;
            }
            .blogModSearch{
                outline: none;
                border: none;
                background-color: transparent;
                border-bottom: 2px solid #ff9f0e;
                width: 100%;
                font-size: 1.3rem;
                padding: 5px;
                color: var(--text-color);
            }
        }
    }
    .highflex{
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 4%;
        gap: 1rem;
        .highblogcard{
            width: 380px;
            overflow: hidden;
            margin-bottom: 30px;
            cursor: pointer !important;
            .bloghighImage{
                flex: 0 0 100%;
                max-width: 100%;
                border-radius: 8px;
                overflow: hidden !important;
                img{
                    max-height: 250px;
                    height: auto!important;
                    border-radius: 0;
                    box-shadow: initial;
                    padding: 0!important;
                    width: 100%;
                    object-fit: cover;
                    filter: brightness(90%);
                    transition: 0.5s;
                    vertical-align: middle;
                    border-style: none;
                    &:hover{
                        margin-top: 0;
                        filter: initial;
                        box-shadow: initial;
                        transform: scale(1.05);
                        transition: 0.5s;
                    }
                }
            }
            .bloghighTitle{
                padding: 10px;
                .bloghighInfo{
                    p{
                        margin-left: 0px;
                        color: var(--text-color);
                        span{
                            text-transform: uppercase;
                        }
                    }
                }
                .aertTitle{
                    color: var(--text-color);
                    h2{
                        font-size: 1.5rem;
                        line-height: 1.2;
                        font-weight: 600;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        max-height: 4rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        word-break: break-word;
                        cursor: pointer;
                        &:hover{
                            transition: .3s;
                            color: var(--text-color);
                        }
                    }
                    p{
                        margin-top: 5px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 4;
                        max-height: 6rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        word-break: break-word;
                        cursor: pointer;
                        color: var(--text-color);
                    }
                }
                .aertReadMore{
                    font-weight: 600;
                    font-size: .875rem;
                    line-height: 1.25rem;
                    white-space: nowrap;
                    display: flex;
                    margin-top: 5px;
                    color: var(--text-color);
                    &:hover{
                        color: var(--text-color);
                        span{
                            margin-right: -5px;
                            margin-left: 10px;
                            color: var(--color--vivid-cyan-blue);
                        }
                    }
                    span{
                        margin-left: 6px;
                        transition: .3s;
                        margin-top: 2.5px;
                        .arrowRight{
                            height: 15px;
                            fill: var(--color--vivid-cyan-blue);
                        }
                    }
                }
            }
        }
    }
}
.blog-sec-top{
    text-align: center;
    padding: 3% 20%;
    padding-bottom: 2%;
    background-color: var(--bg-color);
    h1{
        font-size: 4rem;
        color: var(--text-color);
        @media(max-width: 480px){
            font-size: 2rem;
        }
    }
    p{
        font-size: 0.9rem;
        color: var(--text-color);
        @media(max-width: 480px){
            font-size: 0.7rem;
        }
    }
}
.blog-landing{
    background-color: var(--bg-color);
    .blog-landing-wrapper{
        margin: 20px 5%;
        .spot-blog{
            display: flex;
            flex-wrap: wrap-reverse;
            justify-content: space-between;
            @media(max-width: 1000px){
                flex-direction: column;
            }
            .spot-image{
                width: 49%;
                @media(max-width: 1000px){
                    width: 100%;
                    margin-bottom: 20px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .spot-texts{
                width: 49%;
                color: var(--text-color);
                @media(max-width: 1000px){
                    width: 100%;
                }
                .spot-category{
                    padding: 5px;
                    border-radius: 6px;
                    background-color: #42636fc8;
                    cursor: pointer;
                }
                .spot-date{
                    text-transform: uppercase;
                    margin-left: 10px;
                }
                h2{
                    font-size: 28px;
                    line-height: 34px;
                    letter-spacing: -.48px;
                    margin: 0 0 16px;
                    font-weight: 700;
                    margin-top: 15px;
                    cursor: pointer;
                    a{
                        text-decoration: none;
                        color: var(--text-color);
                    }
                }
                p{
                    a{
                        text-decoration: none;
                        color: var(--text-color);
                    }
                }
                .spot-author{
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                    img{
                        height: 35px;
                        width: 35px;
                        border-radius: 50%;
                    }
                    .spot-author-name{
                        margin-left: 10px;
                        p{
                            font-size: 0.7rem;
                        }
                    }
                }
            }
        }
    }
}
.blogs-showcase{
    background-color: var(--bg-color);
    margin: 0 5%;
    .bloghighHeader{
        display: flex;
        margin: 3%;
        justify-content: space-between;
        margin-bottom: 2rem;
        @media(max-width: 700px){
            justify-content: unset;
        }
        .blogsFileters{
            display: flex;
            @media(max-width: 700px){
                display: none;
            }
            .filter{
                padding: 8px 15px;
                border-radius: 23px;
                font-size: .9rem;
                text-decoration: none;
                color: var(--text-color);
                margin-right: 20px;
                cursor: pointer;
                &:nth-child(1){
                    margin-left: -20px !important;
                }
                &:hover{
                    background-color: #505050;
                }
            }
            .activeFilter{
                background-color: #0088f0;
                &:hover{
                    background-color: #0088f0 !important;
                }
            }
        }
        .viewIcon{
            display: flex;
            margin-top: 10px;
            @media(max-width: 700px){
                justify-content: space-between;
                width: 100%;
            }
            .filtersIcons{
                fill: var(--text-color);
                height: 15px;
                margin: 0 10px;
                cursor: pointer;
            }
        }
    }
    .highflex{
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 4%;
        .highblogcard{
            width: 380px;
            overflow: hidden;
            margin-bottom: 30px;
            cursor: pointer !important;
            .bloghighImage{
                flex: 0 0 100%;
                max-width: 100%;
                border-radius: 8px;
                overflow: hidden !important;
                img{
                    max-height: 250px;
                    height: auto!important;
                    border-radius: 0;
                    box-shadow: initial;
                    padding: 0!important;
                    width: 100%;
                    object-fit: cover;
                    filter: brightness(90%);
                    transition: 0.5s;
                    vertical-align: middle;
                    border-style: none;
                    &:hover{
                        margin-top: 0;
                        filter: initial;
                        box-shadow: initial;
                        transform: scale(1.05);
                        transition: 0.5s;
                    }
                }
            }
            .bloghighTitle{
                padding: 10px;
                .bloghighInfo{
                    p{
                        margin-left: 0px;
                        color: var(--text-color);
                        span{
                            text-transform: uppercase;
                        }
                    }
                }
                .aertTitle{
                    color: var(--text-color);
                    h2{
                        font-size: 1.5rem;
                        line-height: 1.2;
                        font-weight: 600;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        max-height: 4rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        word-break: break-word;
                        cursor: pointer;
                        &:hover{
                            transition: .3s;
                            color: var(--text-color);
                        }
                    }
                    p{
                        margin-top: 5px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 4;
                        max-height: 6rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        word-break: break-word;
                        cursor: pointer;
                        color: var(--text-color);
                    }
                }
                .aertReadMore{
                    font-weight: 600;
                    font-size: .875rem;
                    line-height: 1.25rem;
                    white-space: nowrap;
                    display: flex;
                    margin-top: 5px;
                    color: var(--text-color);
                    &:hover{
                        color: var(--color--vivid-cyan-blue);
                        span{
                            margin-right: -5px;
                            margin-left: 10px;
                            color: var(--text-color);
                        }
                    }
                    span{
                        margin-left: 6px;
                        transition: .3s;
                        margin-top: 2.5px;
                        .arrowRight{
                            height: 15px;
                            fill: var(--color--vivid-cyan-blue);
                        }
                    }
                }
            }
        }
    }
}