/* ======blog highlights stylings====== */
.bloghigh{
    background: var(--bg-color);
    .highwrapper{
        .highcontainer{
            margin: 0 5%;
            padding: 5% 0;
            .bloghighHeader{
                display: flex;
                margin: 0 5%;
                justify-content: space-between;
                margin-bottom: 2rem;
                @media(min-width: 1440px){
                    margin: 0 10%;
                }
                h1{
                    font-size: 2rem;
                    line-height: 2.25rem;
                    font-weight: 700;
                    color: var(--text-color); 
                    @media(min-width: 1440px){
                        margin-top: -10px;
                        margin-bottom: 10px;
                    }
                    @media(max-width: 480px){
                        font-size: 1rem !important;
                        margin-left: -5%;
                        margin-top: -7px;
                    }
                }
                .blogLinks{
                    font-weight: 600;
                    font-size: .875rem;
                    line-height: 1.25rem;
                    white-space: nowrap;
                    display: flex;
                    color: var(--text-color); 
                    &:hover{
                        color: #0088f0;
                        span{
                            margin-right: -5px;
                            margin-left: 10px;
                            color: #0088f0;
                        }
                    }
                    span{
                        margin-left: 5px;
                        transition: .3s;
                        .arrowRight{
                            height: 19px;
                            fill: #0088f0;
                        }
                    }
                }
            }
            .highflex{
                display: flex;
                justify-content: space-evenly;
                flex-direction: row;
                flex-wrap: wrap;
                .highblogcard{
                    width: 350px;
                    overflow: hidden;
                    border-radius: 6px !important;
                    background-color: var(--bg-card);
                    margin-top: 20px;
                    .bloghighImage{
                        flex: 0 0 100%;
                        max-width: 100%;
                        img{
                            max-height: 150px;
                            border-radius: 0;
                            box-shadow: initial;
                            padding: 0!important;
                            width: 100%;
                            object-fit: cover;
                            filter: brightness(90%);
                            transition: 0.5s;
                            vertical-align: middle;
                            border-style: none;
                            &:hover{
                                margin-top: 0;
                                filter: initial;
                                box-shadow: initial;
                                transform: scale(1.05);
                                transition: 0.5s;
                            }
                        }
                    }
                    .bloghighTitle{
                        padding: 10px;
                        .blogLinks{
                            h2{
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                max-height: 3.6rem;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                word-wrap: break-word;
                                word-break: break-word;
                                cursor: pointer;
                                font-size: 1.5rem;
                                line-height: 1.1;
                                color: var(--text-color);
                                &:hover{
                                    transition: .3s;
                                    color: #0088f0;
                                }
                            }
                            p{
                                color: var(--text-color);
                                font-size: 0.9rem;
                                margin-bottom: 10px;
                                
                            }
                        }
                        .blogReadMore{
                            color: #ffffff;
                            padding: 5px 10px;
                            border-radius: 23px;
                            background-color: var(--color--vivid-cyan-blue);
                            cursor: pointer;
                            border: none;
                            outline: none;
                        }
                        .blogHighMeta{
                            margin: 5px 0;
                            color: var(--text-color);                            
                            span{
                                &:nth-child(1){
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}