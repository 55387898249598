.landing {
  background: var(--bg-color);
  background-position: center, center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
}
.landing .lHeader {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
}
.landing .landing-section {
  height: 60vh;
}
@media (max-width: 480px) {
  .landing .landing-section {
    margin-top: 0 !important;
  }
}
.landing .landing-section .landing-wrapper {
  height: 100%;
  margin: 0 10%;
  justify-content: space-between;
  position: relative;
}
@media (max-width: 480px) {
  .landing .landing-section .landing-wrapper {
    margin: 0;
  }
}
.landing .landing-section .landing-wrapper .landing-texts {
  width: 40%;
  margin: 0 !important;
  position: absolute;
  top: 15%;
  left: 5%;
}
@media (min-width: 1440px) {
  .landing .landing-section .landing-wrapper .landing-texts {
    top: 15%;
  }
}
@media (max-width: 480px) {
  .landing .landing-section .landing-wrapper .landing-texts {
    width: 90%;
    margin-top: 20px;
  }
}
.landing .landing-section .landing-wrapper .landing-texts .landing-txt-header {
  font-weight: 900;
  line-height: 1.2;
  color: var(--text-color);
  margin-bottom: 30px;
}
@media (max-width: 480px) {
  .landing .landing-section .landing-wrapper .landing-texts .landing-txt-header {
    font-size: 2.7rem;
  }
}
.landing .landing-section .landing-wrapper .landing-texts .landing-txt-paragraph {
  font-weight: 400;
  color: var(--text-color);
  margin-bottom: 50px;
}
.landing .landing-section .landing-wrapper .landing-texts .lng-cta {
  background-color: #256aff;
  color: #ffffff;
  padding: 10px 25px;
  border-radius: 23px;
  cursor: pointer;
}
.landing .landing-section .landing-wrapper .landing-image {
  margin: 0 !important;
  position: absolute;
  top: 13%;
  right: -5%;
  width: 50%;
  padding-right: 10%;
}
@media (max-width: 480px) {
  .landing .landing-section .landing-wrapper .landing-image {
    display: none !important;
  }
}
.landing .landing-section .landing-wrapper .landing-image .codeplayArea {
  background-color: var(--gradient--pale-ocean);
  padding: 10px;
  transform: skewX(-10deg);
  margin-left: 10%;
}
.landing .landing-section .landing-wrapper .landing-image .img-landing {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.landing .landing-section .landingBgOne, .landing .landing-section .landingBgTwo, .landing .landing-section .landingBgThree {
  position: absolute;
}
.landing .landing-section .landingBgOne {
  top: 0;
  height: 60vh;
  background-color: transparent !important;
  width: 65%;
  z-index: 0 !important;
  margin: 0 0 0 -10%;
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
}
@media (max-width: 680px) {
  .landing .landing-section .landingBgOne {
    background-color: transparent !important;
  }
}
.landing .landing-section .landingBgOne .bGOneUnskew {
  margin: 0 0 0 20%;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  padding-top: 5%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.landing .landing-section .landingBgOne .bGOneUnskew .bgHex {
  position: absolute;
  bottom: 0;
  left: 0;
}
.landing .landing-section .landingBgTwo {
  top: 50%;
  right: 20%;
}/*# sourceMappingURL=landing.css.map */