$bgDarkDark: #010f25;
$colorLight: #f3f3f3;

.brand-logo, .navigation{
    height: 40px;
}
.header{
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: var(--bg-color);
    border-bottom: 1px solid #cbcbcb;
    width: 100%;
    .header-container{
        @media(min-width: 1440px) {
            margin: 0 10%;
        }
        @media(max-width: 1440px) {
            margin: 0 5%;
        }
        .header-wrapper{
            .brand-logo{
                width: 40%;
                position: relative;
                @media(max-width: 480px) {
                    width: 50% !important;
                }
                .logoWrapper{
                    position: relative;
                    top: 0;
                    left: 10%;
                    z-index: 999;
                    width: 150px;
                    height: auto;
                    margin-top: -5px;
                    @media(max-width: 480px) {
                        left: 0;
                        width: 100px;
                    }
                    .logo{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .posUnset{
                position: unset !important;
            }
            .navigation{
                align-items: center;
                margin-top: -20px;
                width: 60%;
                position: relative;
                z-index: 999;
                @media(max-width: 320px){
                    gap: 1rem !important;
                }
                .openMobiMenu{
                    display: none;
                    width: 25px;
                    fill: var(--text-color);
                }
                .menuMbi{
                    .closeMobiMenu{
                        display: none;
                    }
                    ul{
                        align-items: center;
                        .mobiLogo{
                            display: none;
                        }
                        li{
                            .nav-links{
                                letter-spacing: 1.5px;
                                cursor: pointer;
                                margin: 0 20px;
                                color: var(--text-color);
                            }
                        }
                    }
                }
                .header-cta{
                    .header-cta-btn{
                        background-color: #256aff;
                        border-radius: 23px;
                        padding: 8px 25px;
                        color: #ffffff;
                        @media(max-width: 500px){
                            padding: 5px 10px !important;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 1050px){
    .header-container{
        margin: 0 5% !important;
    }
    .brand-logo{
        .logo-text{
            font-size: 2rem;
        }
    }
    .openMobiMenu{
        display: unset !important;
    }
    .shwMobiMenu{
        right: 0 !important;
        left: 0 !important;
        height: 100vh !important;
        &~ .navigation{
            position: unset !important;
        }
    }
    .header-cta{
        .header-cta-btn{
            background-color: #4f60ff;
            border-radius: 6px;
            font-size: 0.9rem;
        }
    }
    .menuMbi{
        position: absolute;
        z-index: 999;
        background-color: var(--bg-color);
        top: 0;
        bottom: 0;
        right: unset;
        left: -200%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: 3s, ease-in-out;
        .closeMobiMenu{
            display: unset !important;
            position: absolute;
            top: 20px;
            right: 25px;
            width: 15px;
            fill: var(--text-color);
            cursor: pointer;
            &:hover{
                fill: #4f60ff;
            }
        }
        ul{
            align-items: center;
            flex-direction: column !important;
            vertical-align: middle;
            margin-top: 150px;
            .mobiLogo{
                display: unset !important;
                position: absolute;
                top: 10px;
                left: 20px;
                font-size: 2rem;
                margin-top: 5px;
                color: var(--text-color);
            }
            li{
                margin: 15px 0;
                .nav-links{
                    font-size: 1.4rem;
                }
            }
        }
    }
}