.footer{
    height: min-content;
    background: var(--bg-color);
    .footerWrapper{
        margin: 5%;
        margin-top: 0;
        padding-top: 20px;
        flex-wrap: wrap;
        .fSections{
            min-width: 200px;
            margin: 10px;
            background-color: #ffffff00;
            flex: 1;
            padding: 20px;
            @media(max-width: 480px){
                padding: 0 10px;
                margin: 5px 10px;
            }
            h1{
                font-weight: 700;
                color: var(--text-color);
            }
            p{
                color: var(--text-color);
            }
            .socialIcons{
                .socLinks{
                    margin: 10px;
                    .facebookLogo{
                        fill: #4267B2;
                        height: 25px;
                    }
                    .twitterLogo{
                        fill: #1DA1F2;
                        height: 25px;
                    }
                    .linkedInLogo{
                        fill: #0077B5;
                        height: 25px;
                    }
                    .instagramLogo{
                        fill: #fd21e3;
                        height: 25px;
                    }
                }
            }
            .fDrop{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .fResources{
                    font-weight: 900;
                    color: var(--text-color);
                }
                .angleDown{
                    fill: #fff;
                    height: 20px;
                    margin-left: 20px;
                    margin-top: -10px;
                    @media(min-width: 480px){
                        display: none !important;
                    }
                }
                .angleRight{
                    transform: rotate(-90deg);
                    transition-property: all;
                    transition-timing-function: cubic-bezier(.4,0,.2,1);
                    transition-duration: .15s;
                }
            }
            ul{
                @media(max-width: 480px){
                    display: none;
                }
                li{
                    margin-bottom: 1rem;
                    color: var(--text-color);
                }
            }
            .showFLinks{
                display: unset !important;
            }
            .faqShow{
                display: flex;
                transition-property: all;
                transition-timing-function: cubic-bezier(.4,0,.2,1);
                transition-duration: .15s;
            }
        }
    }
    .footerCopyright{
        width: 100%;
        text-align: center;
        p{
            text-align: center;
            padding-bottom: 20px;
            color: var(--text-color);
            span{
                span{
                    a{
                        .githubIcon{
                            height: 15px;
                            fill: #fff;
                            display: unset !important;
                            margin-bottom: 3px;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
}